import TransportationCaller from '../../../api/internal/TransportationCaller';
import React, { useState, useEffect } from 'react';
import MultipleEmailInput from '../../../helpers/inputs/MultipleEmailInput';
import PostBody from '../../../api/internal/PostBody';
import Button from '../../../assets/essentials/Button';
import Input from '../../../assets/essentials/Input';
import TextArea from '../../../assets/essentials/TextArea';
import classesMultipleEmailInput from '../../../helpers/inputs/MultipleEmailInput.module.css';
import classes from '../../../assets/Global.module.css';
import DateOnlyUTCFormatter from '../../../helpers/inputs/DateOnlyUTCFormatter';

type SendEmailStatusOptions = 'pending' | 'success' | 'failure' | 'initial';

function CreateInvoice({
  data,
  customerAssignmentId,
  isLoadInvoice,
  HandlePageChange,
  RefreshPage,
}: {
  data: CustomerInvoiceDetails;
  customerAssignmentId: Number;
  isLoadInvoice: boolean;
  HandlePageChange: Function;
  RefreshPage: Function;
}) {
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const [invoiceBlob, setInvoiceBlob] = useState<Blob | null>(null);
  const [isQuickBooksPostPending, setIsQuickBooksPostPending] =
    useState<boolean>(false);
  const [sendEmailStatus, setSendEmailStatus] =
    useState<SendEmailStatusOptions>('initial');
  const [emailRecipients, setEmailRecipients] = useState(
    data?.customerBillingEmails,
  );
  const [neededAttachments, setNeededAttachments] = useState({
    needsInvoice: true,
    needsAdditionalDocuments: true,
  });

  useEffect(() => {
    isLoadInvoice ? GetCustomerLoadInvoice() : GetCustomerRentalInvoice();
  }, [neededAttachments]);

  const CreatePDFBase64 = blob => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        resolve(base64String);
      };
      reader.onerror = error => {
        reject(error);
      };
      reader.readAsDataURL(blob);
    });
  };

  function GetCustomerRentalInvoice() {
    const path = `/Accounting/GetCustomerRentalInvoice/${customerAssignmentId}`;
    TransportationCaller.get(path, {
      responseType: 'blob',
    }).then(response => {
      if (response) {
        const blob = new Blob([response.data], {
          type: 'application/pdf',
        });
        setInvoiceBlob(blob);
        setPdfUrl(URL.createObjectURL(blob));
      }
    });
  }

  function GetCustomerLoadInvoice() {
    const path = `/Accounting/DownloadCustomerLoadInvoice/`;
    const payload = {
      customerAssignmentId: customerAssignmentId,
      needsInvoice: neededAttachments.needsInvoice,
      needsAdditionalDocuments: neededAttachments.needsAdditionalDocuments,
    };
    TransportationCaller.post(path, payload, {
      responseType: 'blob',
    }).then(response => {
      if (response) {
        const blob = new Blob([response.data], {
          type: 'application/pdf',
        });
        setInvoiceBlob(blob);
        setPdfUrl(URL.createObjectURL(blob));
      }
    });
  }

  const NeedsInvoiceDateView = () => (
    <>
      <h3>No Invoice Date for this Receivable.</h3>
      <span className={classes.cTA} onClick={() => HandlePageChange('Details')}>
        Set an Invoice Date.
      </span>
    </>
  );

  const SelectFileToAttach = () => {
    const RadioInput = ({ id, name, label, checked, onChange }) => (
      <label htmlFor={id} className={classes.flexAlignCenterJustifyLeft}>
        <input
          type="radio"
          id={id}
          name={name}
          checked={checked}
          onChange={onChange}
        />
        {label}
      </label>
    );

    return (
      <>
        <label>Attach:</label>
        <div className={`${classes.flex}`}>
          <RadioInput
            id="both"
            name="fileType"
            label="Invoice, POD, & Receipts"
            checked={
              neededAttachments.needsInvoice &&
              neededAttachments.needsAdditionalDocuments
            }
            onChange={() => {
              setNeededAttachments({
                needsInvoice: true,
                needsAdditionalDocuments: true,
              });
            }}
          />
          <RadioInput
            id="invoice"
            name="fileType"
            label="Invoice Only"
            checked={
              neededAttachments.needsInvoice &&
              !neededAttachments.needsAdditionalDocuments
            }
            onChange={() => {
              setNeededAttachments({
                needsInvoice: true,
                needsAdditionalDocuments: false,
              });
            }}
          />
          <RadioInput
            id="pod"
            name="fileType"
            label="POD & Receipts"
            checked={
              !neededAttachments.needsInvoice &&
              neededAttachments.needsAdditionalDocuments
            }
            onChange={() => {
              setNeededAttachments({
                needsInvoice: false,
                needsAdditionalDocuments: true,
              });
            }}
          />
        </div>
      </>
    );
  };

  const CreateQuickbooksInvoiceIdButton = () => {
    function PostInvoiceToQuickbooks() {
      setIsQuickBooksPostPending(true);
      PostBody('Accounting/PostInvoiceToQuickBooks', {
        customerAssignmentId: customerAssignmentId,
      }).then(response => {
        if (response) {
          RefreshPage('Invoice', ['Details', 'Audit', 'Admin']);
        }
      });
    }
    const invoiceHasBeenSentToQuickbooks = data?.quickBooksInvoiceId != null;
    const isDisabled =
      isQuickBooksPostPending ||
      sendEmailStatus === 'pending' ||
      invoiceHasBeenSentToQuickbooks;

    return (
      <div>
        <Button
          variant="good"
          disabled={isDisabled}
          onClick={() => PostInvoiceToQuickbooks()}
          isLoading={isQuickBooksPostPending}
          type="button"
        >
          {invoiceHasBeenSentToQuickbooks
            ? `QuickBooks Invoice ID: ${data?.quickBooksInvoiceId}`
            : 'Post Invoice To QuickBooks'}
        </Button>
      </div>
    );
  };

  const SendCustomerInvoiceEmailForm = () => {
    const HandleSendEmail = e => {
      e.preventDefault();
      setSendEmailStatus('pending');
      CreatePDFBase64(invoiceBlob).then(base64 => {
        const payload = {
          toRecipientAddresses: emailRecipients,
          subject: e.target.subject.value,
          body: e.target.body.value,
          attachmentName: attachmentName,
          invoiceBase64: base64,
          customerAssignmentId: customerAssignmentId,
        };
        PostBody(`/Customer/SendCustomerInvoice`, payload).then(response => {
          if (response) {
            setSendEmailStatus('success');
            RefreshPage(null, ['Details', 'Audit', 'Summary']);
          } else {
            setSendEmailStatus('failure');
          }
        });
      });
    };

    const attachmentName = `${
      isLoadInvoice
        ? `BBI Invoice_Load ${data.loadId}`
        : `BBI Rental ${data.rentalId}`
    }${
      data.invoiceDate ? `_${DateOnlyUTCFormatter(data.invoiceDate)}` : ''
    }.pdf`;

    const defaultInvoiceSubject = neededAttachments.needsAdditionalDocuments
      ? isLoadInvoice
        ? `BBI Invoice # ${data.loadId}`
        : `BBI Rental Invoice # ${data.rentalId}`
      : `BBI Load #${isLoadInvoice ? data.loadId : ''}`;

    const defaultBody = 'See attached.';

    const sendInvoiceButtonText =
      sendEmailStatus === 'success'
        ? 'Email Sent'
        : sendEmailStatus === 'pending'
        ? 'Sending...'
        : 'Send Email';

    const isDisabled =
      sendEmailStatus === 'pending' ||
      sendEmailStatus === 'success' ||
      isQuickBooksPostPending;

    return (
      <>
        <h2>Send Customer Invoice</h2>
        <form
          onChange={() => setSendEmailStatus('initial')}
          onSubmit={e => HandleSendEmail(e)}
        >
          <div
            className={`${classes.attribute} ${classesMultipleEmailInput.container}`}
          >
            <label htmlFor="toRecipientAddresses">
              To: <span className={classes.required}>*</span>
            </label>
            <MultipleEmailInput
              submitButtonId={'submit'}
              emails={emailRecipients}
              setEmails={setEmailRecipients}
              id="toRecipientAddresses"
              name="toRecipientAddresses"
              required={emailRecipients.length === 0}
            />
          </div>
          <div className={`${classes.attribute} ${classes.span3}`}>
            <label htmlFor="subject">
              Subject: <span className={classes.required}>*</span>
            </label>
            <Input
              required
              type="text"
              defaultValue={defaultInvoiceSubject}
              id="subject"
              name="subject"
            />
          </div>
          <div className={`${classes.attribute} ${classes.span2}`}>
            <label htmlFor="body">Body: </label>
            <TextArea
              required
              defaultValue={defaultBody}
              id="body"
              name="body"
            />
          </div>
          {isLoadInvoice && <SelectFileToAttach />}
          <div
            className={`${classes.sideBySideButtons} ${classes.marginTop05Rem}`}
          >
            <Button type="submit" variant="good" disabled={isDisabled}>
              {sendInvoiceButtonText}
            </Button>
            <CreateQuickbooksInvoiceIdButton />
          </div>
        </form>
      </>
    );
  };

  return (
    <div>
      {data.invoiceDate ? (
        <>
          <SendCustomerInvoiceEmailForm />
          <div className={classes.pdfContainer}>
            <iframe title="PDF" id="iframe" src={pdfUrl} />
          </div>
        </>
      ) : (
        <NeedsInvoiceDateView />
      )}
    </div>
  );
}
export default CreateInvoice;
