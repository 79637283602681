import React, { useState, useEffect } from 'react';
import { MdDownload } from 'react-icons/md';

import BBITable from '../../helpers/bBITable/BBITable';
import DateOnlyFormatter from '../../helpers/inputs/DateOnlyFormatter';
import Filter from '../../helpers/filter/Filter';
import FilterData from '../../helpers/filter/FilterData';
import Get from '../../api/internal/Get';
import Header from '../../layouts/Header';
import View from '../../helpers/slab/View';
import ViewCustomer from '../../features/views/customer/Customer';
import ViewLoad from '../../features/views/load/Load';
import ViewRental from '../../features/views/rental/Rental';
import ViewReceivable from '../../features/views/receivable/Receivable';
import PostBody from '../../api/internal/PostBody';

function Receivable({ toggleBoardRefresh }) {
  const [filterValue, setFilterValue] = useState('');
  const [needsInvoiceBoard, setNeedsInvoiceBoard] = useState<
    Array<ReceivableBoard>
  >([]);

  useEffect(() => {
    GetNeedsInvoiceBoard();
  }, [toggleBoardRefresh]);

  function GetNeedsInvoiceBoard() {
    Get(`/Accounting/GetNeedsInvoiceBoard/`).then(response => {
      if (response) {
        setNeedsInvoiceBoard(response.data);
      }
    });
  }

  function DownloadNeedsInvoiceReportCSV(receivables) {
    let receivableIdsToDownload = [];
    receivableIdsToDownload = receivables?.map(x => x.customerAssignmentId);
    if (receivableIdsToDownload.length > 0) {
      const payload = {
        CustomerAssignmentIdList: [...receivableIdsToDownload],
      };
      PostBody('Accounting/DownloadReceivableReportCSV', payload).then(
        response => {
          if (response) {
            const blob = new Blob([response.data], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download =
              new Date().toISOString().split('T')[0].replace(/[-]/g, '') +
              '_Needs Invoice Report.csv';
            a.click();
            URL.revokeObjectURL(url);
          }
        },
      );
    }
  }

  const filteredData = FilterData(needsInvoiceBoard, filterValue);

  const NeedsInvoiceTable = ({ receivableList }) => {
    const formattedReceivableList = receivableList.map(receivable => ({
      ...receivable,
      attribute: receivable.loadId
        ? receivable.loadId
        : receivable.rentalId && 'Rental: ' + receivable.rentalId,
      pickupDate: DateOnlyFormatter(receivable.pickupDate),
      dropOffDate: DateOnlyFormatter(receivable.dropOffDate),
      dueDate: DateOnlyFormatter(receivable.dueDate),
      invoiceDate: DateOnlyFormatter(receivable.invoiceDate),
      total: receivable.total.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    }));

    let columns = [
      {
        key: '1',
        attribute: 'attribute',
        attributeprimaryhidden: 'loadId',
        attributesecondaryhidden: 'rentalId',
        header: 'Load #',
        onClick: e =>
          View(
            e.target.dataset.attributeprimaryhidden ? (
              <ViewLoad loadId={e.target.dataset.attributeprimaryhidden} />
            ) : (
              e.target.dataset.attributesecondaryhidden && (
                <ViewRental
                  rentalId={e.target.dataset.attributesecondaryhidden}
                />
              )
            ),
          ),
        dataType: 'number',
      },
      {
        key: '2',
        attribute: 'reference',
        header: 'Reference',
        attributeprimaryhidden: 'loadId',
        attributesecondaryhidden: 'rentalId',
        onClick: e =>
          View(
            e.target.dataset.attributeprimaryhidden ? (
              <ViewLoad loadId={e.target.dataset.attributeprimaryhidden} />
            ) : (
              e.target.dataset.attributesecondaryhidden && (
                <ViewRental
                  rentalId={e.target.dataset.attributesecondaryhidden}
                />
              )
            ),
          ),
      },
      {
        key: '3',
        attribute: 'paymentStatus',
        attributeprimaryhidden: 'customerAssignmentId',
        attributesecondaryhidden: 'loadId',
        attributetertiaryhidden: 'rentalId',
        header: 'Status',
        onClick: e =>
          View(
            <ViewReceivable
              customerAssignmentId={e.target.dataset.attributeprimaryhidden}
              loadId={e.target.dataset.attributesecondaryhidden}
              rentalId={e.target.dataset.attributetertiaryhidden}
            />,
          ),
        dataType: 'string',
      },
      {
        key: '4',
        attribute: 'customerName',
        attributeprimaryhidden: 'customerId',
        header: 'Customer',
        onClick: e =>
          View(
            <ViewCustomer
              customerName={e.target.innerText}
              customerId={e.target.dataset.attributeprimaryhidden}
            />,
          ),
      },
      {
        key: '5',
        attribute: 'pickupDate',
        header: 'Pickup Date',
        dataType: 'date',
      },
      {
        key: '6',
        attribute: 'dropOffDate',
        header: 'Drop Off Date',
        dataType: 'date',
      },
      {
        key: '7',
        attribute: 'branchName',
        header: 'Branch',
      },
      {
        key: '8',
        attribute: 'invoiceDate',
        header: 'Invoice Date',
        dataType: 'date',
      },
      {
        key: '9',
        attribute: 'dueDate',
        header: 'Due Date',
        dataType: 'date',
      },
      {
        key: '10',
        attribute: 'total',
        header: 'Total',
        dataType: 'currency',
      },
    ];

    return <BBITable data={formattedReceivableList ?? []} columns={columns} />;
  };

  return (
    <>
      <h1>Needs Invoice</h1>
      <Header>
        <div>
          <button
            onClick={() => {
              DownloadNeedsInvoiceReportCSV(filteredData);
            }}
          >
            <MdDownload />
            <span>Download</span>
          </button>
        </div>
        <div>
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter receivables"
          />
        </div>
      </Header>
      {filteredData && <NeedsInvoiceTable receivableList={filteredData} />}
    </>
  );
}

export default Receivable;
