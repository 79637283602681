import React, { useState } from 'react';
import { MdOutlineSpeakerNotes } from 'react-icons/md';

import classes from './Asset.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import PostForm from '../../../api/internal/PostForm';
import Button from '../../../assets/essentials/Button';
import Select from '../../../assets/essentials/Select';
import TextArea from '../../../assets/essentials/TextArea';
import Form from '../../../assets/essentials/Form';
import Input from '../../../assets/essentials/Input';
import StatesAndProvinces from '../../../helpers/options/StatesAndProvinces';

function Asset({
  assetId,
  RefreshPage,
}: {
  assetId: Number;
  RefreshPage: Function;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function CreateMaintenanceLog(e) {
    e.preventDefault();
    const formData = new FormData(e.target);

    PostForm(`/Asset/CreateMaintenanceLog`, formData, {
      assetId: assetId,
    }).then(() => RefreshPage('Maintenance Logs', ['Audit']));
    setIsOpen(false);
  }

  return (
    <>
      <div className={classes.btnHolder}>
        <button type="button" onClick={() => setIsOpen(!isOpen)}>
          <MdOutlineSpeakerNotes />
          <span>Add a Maintenance Log</span>
        </button>
      </div>
      <div className={classes.createMaintenanceLogHolder}>
        {isOpen && (
          <Form onSubmit={e => CreateMaintenanceLog(e)}>
            <div className={classesGlobal.attribute}>
              <label htmlFor="date">
                Maintenance Date{' '}
                <span className={classesGlobal.required}>*</span>
              </label>
              <Input id="date" name="date" type="date" required />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label htmlFor="cost">Cost</label>
              <Input id="cost" name="cost" type="number" min="0" step={0.01} />
            </div>
            <div className={classesGlobal.attribute}>
              <label htmlFor="city">City</label>
              <Input id="city" name="city" type="text" />
            </div>
            <div className={classesGlobal.attribute}>
              <label htmlFor="state">State</label>
              <Select id="state" name="state" defaultValue="">
                <option value="" />
                <StatesAndProvinces />
              </Select>
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
            >
              <label htmlFor="note">Note</label>
              <TextArea id="note" name="note" />
            </div>
            <div>
              <Button type="submit" variant="good" disabled={true}>
                Submit
              </Button>
            </div>
            <div>
              <Button onClick={() => setIsOpen(!isOpen)}>Cancel</Button>
            </div>
          </Form>
        )}
      </div>
    </>
  );
}

export default Asset;
