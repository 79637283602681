import React from 'react';

import TransportationCaller from './TransportationCaller';
import Create from '../../helpers/error/Create';
import Error from '../../helpers/error/Error';

async function PostForm(path: string, formData: FormData, appendix?: object) {
  //sets headers for formData
  const options = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  if (appendix) {
    Object.entries(appendix).forEach(([key, value]) => {
      let modifiedValue = value == null ? '' : value;
      formData.append(key, modifiedValue);
    });
  }

  return TransportationCaller.post(path, formData, options).catch(error => {
    Create(<Error response={error.response.data} />);
  });
}

export default PostForm;
