import React from 'react';
import { BsTrash } from 'react-icons/bs';

import CreateFile from './CreateFile';
import BBITable from '../../../helpers/bBITable/BBITable';
import PostForm from '../../../api/internal/PostForm';
import Delete from '../../../api/internal/Delete';
import DateTimeUTCFormatter from '../../../helpers/inputs/DateTimeUTCFormatter';
import classes from './File.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import Modal from '../../../helpers/modal/Modal';
import Create from '../../../helpers/error/Create';
import Error from '../../../helpers/error/Error';

export type FileModalCategory = 'maintenance-log';

/**
 * Files component for managing file uploads, displaying a list of files, and providing delete functionality.
 *
 * @component
 * @param {BlobFile[]} data - An array of files to be displayed.
 * @param {FileModalCategory} fileModalCategory - The category of where we're uploading files.
 * @param {number} categoryId - The ID for the specific object where we're uploading files. (For example, if fileCategory is 'maintenance-log' then categoryId refers to the ID of the target maintenance log)
 * @param {Function} RefreshModal - Function to refresh the modal after an action is performed.
 * @returns {React.ReactElement} The rendered component.
 */

function Files({
  data,
  fileCategory,
  categoryId,
  RefreshModal,
}: {
  data: Array<BlobFile>;
  categoryId: number;
  fileCategory: FileModalCategory;
  RefreshModal: Function;
}): React.ReactElement {
  const newData = data.map(file => ({
    ...file,
    name: (
      <a href={file.uri} target="_blank" rel="noreferrer">
        {file.name}
      </a>
    ),
    type: file.metadata.documentType,
    date: DateTimeUTCFormatter(file.createdOn),
    updatedBy: file.metadata.uploadedBy,
    delete: (
      <BsTrash
        className={`${classesGlobal.clickable} ${classesGlobal.center}`}
        onClick={() => {
          DeleteFile(file.name);
        }}
      />
    ),
  }));

  const columnConfig = [
    {
      key: '1',
      attribute: 'name',
      header: 'Name',
    },
    {
      key: '2',
      attribute: 'type',
      header: 'Type',
    },
    {
      key: '3',
      attribute: 'date',
      header: 'Date',
    },
    {
      key: '4',
      attribute: 'updatedBy',
      header: 'Uploaded By',
    },
    {
      key: '5',
      attribute: 'delete',
      width: '2ch',
    },
  ];

  function UploadFiles(e) {
    const formData = new FormData(e.target);
    PostForm(`/File/UploadFiles/${fileCategory}-${categoryId}`, formData).then(
      response => {
        if (response) {
          RefreshModal(categoryId);
          if (response.status === 207)
            Create(
              <Error response={response.data} messageCharacterLimit={400} />,
            );
        }
      },
    );
  }

  function DeleteFile(fileName: string) {
    const encodedFileName = encodeURIComponent(fileName);
    Delete(
      `/File/DeleteFile/${fileCategory}-${categoryId}/${encodedFileName}`,
    ).then(() => {
      RefreshModal(categoryId);
    });
  }
  return (
    <Modal>
      <div className={classes.filesModalHolder}>
        <CreateFile
          UploadFile={UploadFiles}
          uploadFileListType={fileCategory}
          defaultValue={'Maintenance'}
        />
        {data.length !== 0 ? (
          <BBITable
            data={newData}
            columns={columnConfig}
            stickyTableHead={false}
          />
        ) : (
          <div>No files have been uploaded.</div>
        )}
      </div>
    </Modal>
  );
}
export default Files;
