import React from 'react';
import BBITable from '../../../helpers/bBITable/BBITable';
import CreateMaintenanceLog from '../../creates/notes/Asset';
import CreateModal from '../../../helpers/modal/CreateModal';
import classesGlobal from '../../../assets/Global.module.css';
import FilesModal from '../../creates/file/FilesModal';
import Get from '../../../api/internal/Get';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';

function MaintenanceLogs({
  data,
  assetId,
  RefreshPage,
}: {
  data: MaintenanceLogs;
  assetId: number;
  RefreshPage: Function;
}) {
  const formattedMaintenanceLogData = data.maintenanceLogList?.map(
    maintenanceLog => ({
      ...maintenanceLog,
      date: DateOnlyFormatter(maintenanceLog.date),
      viewFiles: (
        <div
          className={classesGlobal.cTA}
          onClick={() => {
            GetAndViewFiles(maintenanceLog.maintenanceLogId);
          }}
        >
          View Files
        </div>
      ),
    }),
  );

  function GetAndViewFiles(maintenanceLogId: number) {
    Get(`/File/GetAllFiles/maintenance-log-${maintenanceLogId}`).then(
      response => {
        if (response) {
          let maintenanceLogFiles: Array<BlobFile> = response.data;
          return CreateModal(
            <FilesModal
              data={maintenanceLogFiles}
              fileCategory={'maintenance-log'}
              categoryId={maintenanceLogId}
              RefreshModal={GetAndViewFiles}
            />,
          );
        }
      },
    );
  }

  let columnConfig = [
    {
      key: '1',
      attribute: 'createdBy',
      header: 'Created By',
      width: '20ch',
    },
    {
      key: '2',
      attribute: 'date',
      header: 'Maintenance Date',
      width: '20ch',
    },
    {
      key: '3',
      attribute: 'cost',
      header: 'Cost',
      dataType: 'currency',
    },
    {
      key: '4',
      attribute: 'city',
      header: 'City',
    },
    {
      key: '5',
      attribute: 'state',
      header: 'State',
    },
    {
      key: '6',
      attribute: 'note',
      header: 'Note',
    },
    {
      key: '7',
      attribute: 'viewFiles',
      width: '8ch',
    },
  ];

  return (
    <>
      <CreateMaintenanceLog assetId={assetId} RefreshPage={RefreshPage} />
      <BBITable data={formattedMaintenanceLogData} columns={columnConfig} />
    </>
  );
}
export default MaintenanceLogs;
