import React from 'react';

import TransportationCaller from './TransportationCaller';
import Create from '../../helpers/error/Create';
import Error from '../../helpers/error/Error';

async function PostBody(path: string, data: object) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return TransportationCaller.post(path, data, options).catch(error => {
    Create(<Error response={error.response.data} />);
  });
}

export default PostBody;
