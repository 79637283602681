import React, { useState, useRef } from 'react';

import classes from '../../../assets/Global.module.css';
import TextArea from '../../../assets/essentials/TextArea';
import GoogleSearchBoxEstablishment from '../../../helpers/inputs/GoogleSearchBoxEstablishment';
import Input from '../../../assets/essentials/Input';
import Create from '../../../helpers/error/Create';
import Error from '../../../helpers/error/Error';
import StatesAndProvinces from '../../../helpers/options/StatesAndProvinces';
import Select from '../../../assets/essentials/Select';
import TransportationCaller from '../../../api/internal/TransportationCaller';

function CreateLocationFromBody({
  UpdateParam,
  stopIndex,
  stop,
}: {
  UpdateParam: Function;
  stopIndex: number;
  stop: CreateStopParam;
}) {
  const MINIMUM_SEARCH_CHARS = 1;
  const [searchResults, setSearchResults] = useState([]);
  const [showCreateLocation, setShowCreateLocation] = useState(false);
  const [locationInfo, setLocationInfo] = useState(stop?.locationObj);
  const [locationParam] = useState<LocationInfo>({
    locationId: null,
    ianaTimeZone: '',
    locationName: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    phone: '',
    phoneExt: '',
    email: '',
    hours: '',
    directions: '',
    specialInstructions: '',
    website: '',
    searchResult: '',
  });
  const [searchValue, setSearchValue] = useState(locationInfo?.searchResult);
  const createLocationButtonRef = useRef(null);
  // function for updating the locationParameters for the stop object and the locationInfo for HTML
  function UpdateLocation(newValue, attributeName) {
    // trimming to avoid sending empty values - may be causing controlled input warnings
    if (newValue?.toString().trim().length !== 0) {
      locationParam[attributeName] = newValue;
    }
    setLocationInfo(locationParam);
    UpdateParam(locationParam, 'locationObj', stopIndex);
  }
  /*
     - Resetting the location object has to be handled differently for each case
     - The searchTerm in HandleDBSearch must be maintained between responses from the backend
     - Setting locationInfo to null will not rerender, so the user can keep typing
     - Creating a new location needs the page to rerender to clear the fields and prevent the user from sumbitting
    */
  function ResetLocation(renderLocationObj) {
    // checking DB
    if (!renderLocationObj) {
      setLocationInfo(null); // will not trigger a rerender
    } else {
      // creating new location
      setLocationInfo({
        // React will treat this like a new object and rerender to clear out the fields
        ianaTimeZone: '',
        locationName: '',
        addressLineOne: '',
        addressLineTwo: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        phone: '',
        phoneExt: '',
        email: '',
        hours: '',
        directions: '',
        specialInstructions: '',
        website: '',
        searchResult: '',
      });
    }
    UpdateParam(null, 'locationObj', stopIndex);
  }
  // Search current location directory
  function HandleDBSearch(searchTerm) {
    const renderLocationObj = false;
    setSearchValue(searchTerm);
    if (searchResults.length) {
      let searchLocationMatch = searchResults.find(
        result => result.searchResult === searchTerm,
      );
      if (searchLocationMatch) {
        UpdateLocation(searchLocationMatch?.addressLineOne, 'addressLineOne');
        UpdateLocation(searchLocationMatch?.addressLineTwo, 'addressLineTwo');
        UpdateLocation(searchLocationMatch?.searchResult, 'searchResult');
        UpdateLocation(searchLocationMatch?.locationName, 'locationName');
        UpdateLocation(searchLocationMatch?.city, 'city');
        UpdateLocation(searchLocationMatch?.state, 'state');
        UpdateLocation(searchLocationMatch?.zip, 'zip');
        UpdateLocation(searchLocationMatch?.note, 'note');
        UpdateLocation(searchLocationMatch?.country, 'country');
        UpdateLocation(
          searchLocationMatch?.specialInstructions,
          'specialInstructions',
        );
        UpdateParam(
          searchLocationMatch?.ianaTimeZone,
          'ianaTimeZone',
          stopIndex,
        );
        UpdateParam(searchLocationMatch?.locationId, 'locationId', stopIndex);
      } else {
        ResetLocation(renderLocationObj);
      }
    } else {
      ResetLocation(renderLocationObj);
    }
    // calls the backend with each change of searchTerm to filter the datalist
    if (searchTerm.length >= MINIMUM_SEARCH_CHARS) {
      const encodedSearchString = encodeURIComponent(searchTerm);
      TransportationCaller.get(
        `/Location/SearchLocationByLocationName?searchTerm=${encodedSearchString}`,
      ).then(response => {
        if (!response || response.status === 204) {
          createLocationButtonRef?.current.focus();
          return;
        }
        setSearchResults(response?.data);
      });
    }
  }

  // Extract address components from Google Place
  function HandleNewLocationCallBack(place) {
    const renderLocationObj = true;
    const {
      name,
      formatted_phone_number: phone,
      website,
      address_components,
    } = place;

    let addressLineOne = '';
    let streetNumber = '';
    let streetName = '';
    let city = '';
    let state = '';
    let zip = '';
    let country = '';
    let requiredFields = [];

    address_components.forEach(googleResult => {
      googleResult.types.forEach(type => {
        if (type === 'street_number') {
          streetNumber = googleResult.short_name;
        } else if (type === 'route') {
          streetName = googleResult.short_name;
        } else if (type === 'locality') {
          city = googleResult.short_name;
        } else if (type === 'administrative_area_level_1') {
          state = googleResult.short_name;
        } else if (type === 'postal_code') {
          zip = googleResult.short_name;
        } else if (type === 'country') {
          country = googleResult.short_name;
        }
      });

      if (streetNumber && streetName) {
        addressLineOne = streetNumber + ' ' + streetName;
      }
      // Setting this to place.name after each search to ensure all other fields update.
      setSearchValue(place.name);
    });

    // putting the required fields into an array to check for empty values
    requiredFields.push(addressLineOne, city, state, zip, country);
    for (const field of requiredFields) {
      if (field.trim() === '') {
        ResetLocation(renderLocationObj);
        Create(
          <Error response="Location does not contain all required fields. Please try a different location." />,
        );
        return;
      }
    }
    UpdateLocation(name, 'locationName');
    UpdateLocation(addressLineOne, 'addressLineOne');
    UpdateLocation(city, 'city');
    UpdateLocation(state, 'state');
    UpdateLocation(zip, 'zip');
    UpdateLocation(country, 'country');
    UpdateLocation(phone, 'phone');
    UpdateLocation(website, 'website');

    //Removes locationId from stop object when creating a new location
    UpdateParam(null, 'locationId', stopIndex);
  }

  const AutoCompleteBehavior = showCreateLocation ? (
    // If we are creating a location, we want the search box to search Google for location info
    <>
      <GoogleSearchBoxEstablishment
        assignPlace={HandleNewLocationCallBack}
        id="location"
      />
      <div className={classes.cTA}>
        <label className={classes.cTA}>
          Search existing locations
          <div className={classes.hiddenToggler}>
            <Input
              type="checkbox"
              defaultChecked={showCreateLocation}
              onChange={() => {
                setShowCreateLocation(!showCreateLocation);
                ResetLocation(true);
              }}
              name="toggleNewLocation"
              className={classes.hiddenToggler}
              value={undefined}
            />
          </div>
        </label>
      </div>
    </>
  ) : (
    // If we are not creating a location, we will display the datalist that calls the backend
    <>
      <Input
        type="text"
        onChange={e => HandleDBSearch(e.target.value)}
        placeholder="Search for saved locations..."
        list="optionsList"
        name="location"
        id="location"
        autoComplete="off"
      />
      <div
        className={`${classes.cTA}`}
        tabIndex={0}
        ref={createLocationButtonRef}
        onClick={e => {
          e.preventDefault();
          setShowCreateLocation(!showCreateLocation);
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            setShowCreateLocation(true);
          }
        }}
      >
        <label className={classes.cTA}>Add new location</label>
      </div>
      <datalist id="optionsList">
        {searchResults?.map(location => (
          <option key={location.locationId} value={location.searchResult} />
        ))}
      </datalist>
    </>
  );

  // Editable fields that are only rendered when the createLocation variable is true
  const CreateLocationAdditionalFields = () => {
    return (
      <>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>Hours</label>
          <TextArea
            defaultValue={locationInfo?.hours}
            onChange={e => {
              UpdateLocation(e.target.value, 'hours');
            }}
          />
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>Directions</label>
          <TextArea
            defaultValue={locationInfo?.directions}
            onChange={e => UpdateLocation(e.target.value, 'directions')}
            type="text"
          />
        </div>
      </>
    );
  };

  return (
    <React.Fragment key={stopIndex}>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <strong>Location Information</strong>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>Auto-Complete</label>
        {AutoCompleteBehavior}
      </div>

      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>
          Name <span className={classes.required}>*</span>
        </label>
        <Input disabled value={locationInfo?.locationName} />
      </div>
      <div className={classes.attribute}>
        <label>
          Address Line One <span className={classes.required}>*</span>
        </label>
        <Input disabled value={locationInfo?.addressLineOne} />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Address Line Two</label>
        <Input disabled value={locationInfo?.addressLineTwo} />
      </div>
      <div className={classes.attribute}>
        <label>
          City <span className={classes.required}>*</span>
        </label>
        <Input disabled value={locationInfo?.city} />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>
          State/Province <span className={classes.required}>*</span>
        </label>
        <Select disabled value={locationInfo?.state}>
          <option value="" />
          <StatesAndProvinces />
        </Select>
      </div>
      <div className={classes.attribute}>
        <label>
          Zip <span className={classes.required}>*</span>
        </label>
        <Input disabled value={locationInfo?.zip} />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>
          Country <span className={classes.required}>*</span>
        </label>
        <Select disabled value={locationInfo?.country}>
          <option value="" />
          <option value="US">US</option>
          <option value="CA">Canada</option>
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>Special Instructions</label>
        <TextArea
          defaultValue={locationInfo?.specialInstructions}
          disabled={showCreateLocation ? false : true}
          onChange={e => UpdateLocation(e.target.value, 'specialInstructions')}
        />
      </div>
      {showCreateLocation && <CreateLocationAdditionalFields />}
    </React.Fragment>
  );
}
export default CreateLocationFromBody;
