import React from 'react';
import type { ComponentPropsWithoutRef } from 'react';
import classes from './Input.module.css';

interface Select extends ComponentPropsWithoutRef<'select'> {
  children: React.ReactNode;
}

function Select({ children, ...rest }: Select) {
  return (
    <select className={classes.select} {...rest}>
      {children}
    </select>
  );
}

export default Select;
