import React, { useState } from 'react';
import LatestLocationIcon from '../../../assets/trimbleDestinationIcon.png';
import Get from '../../../api/internal/Get';
import AssetLocationMap from '../../../helpers/AssetLocationMap';
import BBITable from '../../../helpers/bBITable/BBITable';
import View from '../../../helpers/slab/View';
import ViewLoad from '../../views/load/Load';
import DateTimeUTCFormatter from '../../../helpers/inputs/DateTimeUTCFormatter';
import Button from '../../../assets/essentials/Button';
import classes from './AssetTracking.module.css';

type AssetTrackingProps = {
  data: AssetTracking;
  assetId: number;
  loadId: number | null;
  RefreshPage: Function;
};

function AssetTracking({
  data,
  assetId,
  loadId,
  RefreshPage,
}: AssetTrackingProps) {
  const { samsaraAssetId, assetLocations } = data;
  const [isLoading, setIsLoading] = useState(false);
  const assetHasLocationHistory = assetLocations?.length > 0;

  function GetAssetLocation() {
    setIsLoading(true);
    let url = `/Asset/GetSamsaraAssetLocation?assetId=${assetId}`;
    if (loadId) {
      url += `&loadId=${loadId}`;
    }
    Get(url)
      .then(response => {
        if (response) {
          RefreshPage('Tracking');
        }
      })
      .finally(() => setIsLoading(false));
  }

  const assetCoordinates = assetLocations.map(location => ({
    latitude: location.latitude,
    longitude: location.longitude,
    date: location.locationDate,
  }));

  const AssetLocationTable = () => {
    const formattedAssetLocations = assetLocations?.map(assetLocation => ({
      ...assetLocation,
      locationDate: DateTimeUTCFormatter(assetLocation?.locationDate),
    }));

    const columnConfig = [
      {
        key: '1',
        attribute: 'assetName',
        header: 'Asset Name',
      },
      {
        key: '2',
        attribute: 'driverName',
        header: 'Driver',
      },
      {
        key: '3',
        attribute: 'loadId',
        header: 'Load ID',
        onClick: e => View(<ViewLoad loadId={e.target.innerText} />),
      },
      {
        key: '4',
        attribute: 'estimatedAddress',
        header: 'Estimated Address',
      },
      {
        key: '5',
        attribute: 'locationDate',
        header: 'Location Date',
      },
      {
        key: '6',
        attribute: 'odometerMiles',
        header: 'Odometer',
      },
    ];
    return <BBITable data={formattedAssetLocations} columns={columnConfig} />;
  };

  const LatestLocationSection = () => {
    //trusting that assetLocations are being sorted by date, then primary key via the backend correctly
    const mostRecentAssetLocation = assetLocations[0];

    return mostRecentAssetLocation ? (
      <section className={classes.latestLocationSection}>
        <h2>Latest Location</h2>
        <div className={classes.latestLocationAddressIcon}>
          <img src={LatestLocationIcon} alt="Latest Location Icon" />
          <span>
            {`${mostRecentAssetLocation?.estimatedAddress} @
          ${DateTimeUTCFormatter(mostRecentAssetLocation.locationDate)}`}
          </span>
        </div>
      </section>
    ) : (
      <h2>No recorded locations. </h2>
    );
  };

  const UpdateAssetLocationButton = () => {
    const buttonText = isLoading
      ? 'Tracking...'
      : samsaraAssetId
      ? 'Update Asset Location'
      : 'Samsara ID is required for Tracking';
    return (
      <Button
        variant="good"
        onClick={() => GetAssetLocation()}
        disabled={isLoading || !samsaraAssetId}
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <>
      <LatestLocationSection />
      <UpdateAssetLocationButton />
      {assetHasLocationHistory && (
        <>
          <h2>Location History</h2>
          <div className={classes.mapContainer}>
            <AssetLocationMap coordinates={assetCoordinates} />
          </div>
        </>
      )}
      <AssetLocationTable />
    </>
  );
}

export default AssetTracking;
