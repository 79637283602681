import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AiFillCaretDown, AiOutlineUser } from 'react-icons/ai';
import { FiTruck } from 'react-icons/fi';
import { IoLocationOutline } from 'react-icons/io5';
import { SiQuickbooks } from 'react-icons/si';
import { NavLink } from 'react-router-dom';
import { RiGroupLine } from 'react-icons/ri';
import { TbRoute } from 'react-icons/tb';
import { RxHamburgerMenu } from 'react-icons/rx';
import { AiOutlineCalculator, AiOutlineShop } from 'react-icons/ai';
import { FiZap } from 'react-icons/fi';
import { BsShieldExclamation } from 'react-icons/bs';
import { MdOutlineVpnKey } from 'react-icons/md';
import { useRoleAuthorization } from '../hooks/useRoleAuthorization';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi';

import { ACCOUNTING_AUTHORIZED_ROLES } from '../auth/AuthRoles';
import classes from './Sidebar.module.css';

function Sidebar() {
  const [isAccountingOpen, setIsAccountingOpen] = useState(false);
  const [isUserOpen, setIsUserOpen] = useState(false);
  const [isAssetOpen, setIsAssetOpen] = useState(false);
  let path = useLocation().pathname;
  const userHasAccountingRoles = useRoleAuthorization(
    ACCOUNTING_AUTHORIZED_ROLES,
  );

  const UserNavLinks = () => {
    return (
      <section className={classes.dropDown}>
        <label
          onClick={() => setIsUserOpen(!isUserOpen)}
          className={classes.toggleHamburger}
        >
          <AiOutlineUser title="User" />
          <div>
            User
            <span
              className={
                isUserOpen ? classes.toggleArrowActive : classes.toggleArrow
              }
            >
              <AiFillCaretDown />
            </span>
          </div>
        </label>
        <div
          className={
            isUserOpen ? classes.dropDownOptionsActive : classes.dropDownOptions
          }
        >
          <NavLink
            to="user/driver"
            className={({ isActive }) => (isActive ? classes.activeLink : '')}
          >
            <RiGroupLine title="Driver" />
            <div>Driver</div>
          </NavLink>
          <NavLink
            to="user/dispatch"
            className={({ isActive }) => (isActive ? classes.activeLink : '')}
          >
            <RiGroupLine title="Dispatch" />
            <div>Dispatch</div>
          </NavLink>
        </div>
      </section>
    );
  };

  const AssetNavLinks = () => {
    return (
      <section className={classes.dropDown}>
        <label
          onClick={() => setIsAssetOpen(!isAssetOpen)}
          className={classes.toggleHamburger}
        >
          <FiTruck title="Asset" />
          <div>
            Asset
            <span
              className={
                isAssetOpen ? classes.toggleArrowActive : classes.toggleArrow
              }
            >
              <AiFillCaretDown />
            </span>
          </div>
        </label>
        <div
          className={
            isAssetOpen
              ? classes.dropDownOptionsActive
              : classes.dropDownOptions
          }
        >
          <NavLink
            to="asset/truck"
            className={({ isActive }) => (isActive ? classes.activeLink : '')}
          >
            <RiGroupLine title="Truck" />
            <div>Truck</div>
          </NavLink>
          <NavLink
            to="asset/trailer"
            className={({ isActive }) => (isActive ? classes.activeLink : '')}
          >
            <RiGroupLine title="Trailer" />
            <div>Trailer</div>
          </NavLink>
          <NavLink
            to="asset/assetlocations"
            className={({ isActive }) => (isActive ? classes.activeLink : '')}
          >
            <RiGroupLine title="Asset Locations" />
            <div>Asset Locations</div>
          </NavLink>
        </div>
      </section>
    );
  };

  const AccountingNavLinks = () => {
    return (
      <section className={classes.dropDown}>
        <label
          onClick={() => setIsAccountingOpen(!isAccountingOpen)}
          className={classes.toggleHamburger}
        >
          <AiOutlineCalculator title="Accounting" />
          <div>
            Accounting
            <span
              className={
                isAccountingOpen
                  ? classes.toggleArrowActive
                  : classes.toggleArrow
              }
            >
              <AiFillCaretDown />
            </span>
          </div>
        </label>
        <div
          className={
            isAccountingOpen
              ? classes.dropDownOptionsActive
              : classes.dropDownOptions
          }
        >
          <NavLink
            to="accounting/needsinvoice"
            className={({ isActive }) => (isActive ? classes.activeLink : '')}
          >
            <RiGroupLine title="Needs Invoice" />
            <div>Needs Invoice</div>
          </NavLink>
          <NavLink
            to="accounting/receivable/outstanding"
            className={({ isActive }) =>
              isActive || path.includes('/accounting/receivable')
                ? classes.activeLink
                : ''
            }
          >
            <RiGroupLine title="Receivable" />
            <div>A/R</div>
          </NavLink>
          <NavLink
            to="accounting/payable/outstanding"
            className={({ isActive }) =>
              isActive || path.includes('/accounting/payable')
                ? classes.activeLink
                : ''
            }
          >
            <RiGroupLine title="Payable" />
            <div>A/P</div>
          </NavLink>
          <NavLink
            to="accounting/payroll/outstanding"
            className={({ isActive }) =>
              isActive || path.includes('/accounting/payroll')
                ? classes.activeLink
                : ''
            }
          >
            <RiGroupLine title="Payroll" />
            <div>Payroll</div>
          </NavLink>
          <NavLink
            to="accounting/employeeaccounts/esa"
            className={({ isActive }) =>
              isActive || path.includes('/employeeaccounts')
                ? classes.activeLink
                : ''
            }
          >
            <RiGroupLine title="ESA" />
            <div>Employee Accounts</div>
          </NavLink>
          <NavLink
            to="accounting/reports/financials/customer"
            className={({ isActive }) =>
              isActive || path.includes('/reports/financials')
                ? classes.activeLink
                : ''
            }
          >
            <RiGroupLine title="Reports" />
            <div>Reports</div>
          </NavLink>
          <NavLink
            to="accounting/quickbooks/"
            className={({ isActive }) => (isActive ? classes.activeLink : '')}
          >
            <SiQuickbooks title="QuickBooks" />
            <div>QuickBooks</div>
          </NavLink>
        </div>
      </section>
    );
  };

  return (
    <div className={classes.sidebar}>
      <input id="checkboxToggleHamburger" type="checkbox" />
      <label
        htmlFor="checkboxToggleHamburger"
        className={classes.toggleHamburger}
      >
        <RxHamburgerMenu />
      </label>
      <NavLink
        to="/"
        className={({ isActive }) => (isActive ? classes.activeLink : '')}
      >
        <FiZap title="Action Board" />
        <div>Action Board</div>
      </NavLink>
      <NavLink
        to="load/"
        className={({ isActive }) => (isActive ? classes.activeLink : '')}
      >
        <TbRoute title="Load" />
        <div>Load</div>
      </NavLink>
      <NavLink
        to="rental/"
        className={({ isActive }) => (isActive ? classes.activeLink : '')}
      >
        <MdOutlineVpnKey title="Rental" />
        <div>Rental</div>
      </NavLink>
      <NavLink
        to="customer"
        className={({ isActive }) => (isActive ? classes.activeLink : '')}
      >
        <RiGroupLine title="Customer" />
        <div>Customer</div>
      </NavLink>
      <NavLink
        to="vendor"
        className={({ isActive }) => (isActive ? classes.activeLink : '')}
      >
        <AiOutlineShop title="Vendor" />
        <div>Vendor</div>
      </NavLink>
      <NavLink
        to="location"
        className={({ isActive }) => (isActive ? classes.activeLink : '')}
      >
        <IoLocationOutline title="Location" />
        <div>Location</div>
      </NavLink>
      <UserNavLinks />
      <AssetNavLinks />
      {userHasAccountingRoles && <AccountingNavLinks />}
      <NavLink
        to="claims/"
        className={({ isActive }) => (isActive ? classes.activeLink : '')}
      >
        <BsShieldExclamation title="Claims" />
        <div>Claims</div>
      </NavLink>
      <NavLink
        to="documents/"
        className={({ isActive }) => (isActive ? classes.activeLink : '')}
      >
        <HiOutlineDocumentDuplicate title="Documents" />
        <div>Documents</div>
      </NavLink>
    </div>
  );
}

export default Sidebar;
