function DateOnlyUTCFormatter(dateTimeObject) {
  if (dateTimeObject) {
    // add "Z" for UTC time
    if (!dateTimeObject.endsWith('Z')) {
      dateTimeObject += 'Z';
    }
    const dateTime = new Date(dateTimeObject);
    if (!dateTime) {
      return null;
    }
    const dateString = dateTime.toLocaleDateString();
    return dateString;
  }
  return null;
}

export default DateOnlyUTCFormatter;
