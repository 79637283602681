import React from 'react';

import TransportationCaller from './TransportationCaller';
import Create from '../../helpers/error/Create';
import Error from '../../helpers/error/Error';

async function Get(path: string) {
  return TransportationCaller.get(path).catch(error => {
    Create(<Error response={error.response.data} />);
  });
}

export default Get;
