import React, { useEffect } from 'react';

import classes from './Error.module.css';
import Button from '../../assets/essentials/Button';
import Close from './Close';

function Error({
  response,
  messageCharacterLimit = 200,
}: {
  response: string | object;
  messageCharacterLimit?: number;
}) {
  useEffect(() => {
    const handleEsc = event => {
      let escapeKeyCode = 27;
      if (event.keyCode === escapeKeyCode) {
        Close();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);
  const genericErrorMessage = 'An error has occurred. Please try again.';
  const message =
    typeof response === 'string' && response.length < messageCharacterLimit
      ? response
      : genericErrorMessage;

  return (
    <div className={classes.error}>
      <h2>Error</h2>
      <h3>{message}</h3>
      <Button variant="bad" onClick={Close}>
        Cancel
      </Button>
    </div>
  );
}

export default Error;
