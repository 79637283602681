import React, { useEffect, useState } from 'react';
import Slab from '../../../../helpers/slab/Slab';
import Details from './Details';
import Summary from './Summary';
import Files from '../../../creates/file/Files';
import Audit from '../../generic/Audit';
import Get from '../../../../api/internal/Get';
import MaintenanceLogs from '../MaintenanceLogs';
import AssetTracking from '../AssetTracking';

function Truck({
  assetId,
  truckName,
  startPage,
}: {
  assetId: number;
  truckName: string;
  startPage?: string;
}) {
  const [currentPage, setCurrentPage] = useState();
  const [truckDataObject] = useState<TruckDataObject>({
    Audit: null,
    Details: null,
    Files: null,
    'Maintenance Logs': null,
    Summary: null,
    Tracking: null,
  });

  useEffect(() => {
    startPage ? HandlePageChange(startPage) : HandlePageChange('Summary');
  }, []);

  function HandlePageChange(params) {
    if (!truckDataObject[params] && pages[params].callerPath) {
      Get(pages[params].callerPath).then(response => {
        if (response) {
          truckDataObject[params] = response.data;
        }
        AssignPages();
        setCurrentPage(params);
      });
    } else {
      setCurrentPage(params);
    }
  }

  function RefreshPage(currentPage: string, additionalPages: string[]) {
    setCurrentPage(null);
    truckDataObject[currentPage] = null;
    additionalPages?.forEach(pageName => {
      truckDataObject[pageName] = null;
    });
    HandlePageChange(currentPage);
  }

  let pages = {};
  AssignPages();
  function AssignPages() {
    pages = {
      Summary: {
        content: (
          <Summary
            HandlePageChange={HandlePageChange}
            data={truckDataObject.Summary}
          />
        ),
        callerPath: `/Asset/GetTruckSummary/${assetId}`,
      },
      Details: {
        content: (
          <Details
            data={truckDataObject.Details}
            assetId={assetId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Asset/GetTruckDetails/${assetId}`,
      },
      'Maintenance Logs': {
        content: (
          <MaintenanceLogs
            data={truckDataObject['Maintenance Logs']}
            assetId={assetId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Asset/GetMaintenanceLogs/${assetId}`,
      },
      Files: {
        content: (
          <Files
            data={truckDataObject.Files}
            fileCategory={'asset'}
            categoryId={assetId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/File/GetAllFiles/asset-${assetId}`,
      },
      Audit: {
        content: <Audit data={truckDataObject.Audit} />,
        callerPath: `/Asset/GetAssetAudit/${assetId}`,
      },
      Tracking: {
        content: (
          <AssetTracking
            data={truckDataObject.Tracking}
            assetId={assetId}
            loadId={null}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Asset/GetAllLocationsForAsset/${assetId}`,
      },
    };
  }

  return (
    <Slab
      title={'Truck: ' + truckName}
      pages={pages}
      currentPage={currentPage}
      HandlePageChange={HandlePageChange}
      dataObject={truckDataObject}
    />
  );
}
export default Truck;
