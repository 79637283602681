import React from 'react';
import type { ComponentPropsWithoutRef } from 'react';
import classes from './Button.module.css';
import { LoadingSpinner } from '../../helpers/loadingSpinner/LoadingSpinner';

/**
 * Button component for creating pre-stylied buttons.
 *
 * Intrinsic button attributes can and should be applied when necessary.
 *
 * These buttons can pair with the Form component for additional functionality.
 *
 * @param {string} variant - `string?` - Used to set the color of the button using 'good' or 'bad'.
 * @param {*} children - `ReactNode` - Used to set the text within the button.
 * @param {boolean} [props.isLoading=false] - If true, displays a loading spinner and hides button text.
 * @returns {*} `JSX.Element` - The stylized button.
 *
 * @example
 * <Button variant='good' type='submit' disabled={true} isLoading={isLoading}>
 *    Save Changes
 * </Button>
 */

interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  variant?: string;
  isLoading?: boolean;
  children: React.ReactNode;
}

function Button({
  variant,
  children,
  isLoading = false,
  ...rest
}: ButtonProps) {
  return (
    <button className={`${classes.button} ${classes[variant]}`} {...rest}>
      <span className={isLoading ? classes.hiddenText : ''}>{children}</span>
      {isLoading && <LoadingSpinner />}
    </button>
  );
}

export default Button;
