import React from 'react';

import Create from '../../helpers/error/Create';
import Error from '../../helpers/error/Error';
import TransportationCaller from './TransportationCaller';

async function PutForm(path: string, formData: FormData, appendix?: object) {
  //sets headers for formData
  const options = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  };

  if (appendix) {
    Object.entries(appendix).forEach(([key, value]) => {
      formData.append(key, value);
    });
  }

  return TransportationCaller.put(path, formData, options).catch(error => {
    Create(<Error response={error.response.data} />);
    throw error;
  });
}

export default PutForm;
