import React, { useEffect, useState } from 'react';
import TransportationCaller from '../../../api/internal/TransportationCaller';
import classes from '../../../assets/Global.module.css';
import classesMultipleEmailInput from '../../../helpers/inputs/MultipleEmailInput.module.css';
import MultipleEmailInput from '../../../helpers/inputs/MultipleEmailInput';
import Input from '../../../assets/essentials/Input';
import TextArea from '../../../assets/essentials/TextArea';
import Button from '../../../assets/essentials/Button';
import PostBody from '../../../api/internal/PostBody';

function LoadDispatch({ loadId, data, RefreshPage }) {
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const [emailRecipients, setEmailRecipients] = useState<string[]>(data)
const [sendEmailStatus, setSendEmailStatus] = useState<SendEmailStatusOptions>('initial')

  type SendEmailStatusOptions = 'pending' | 'success' | 'failure' | 'initial';


  useEffect(() => {
    const path = `/Load/GetLoadDispatchPDF/${loadId}`;
    TransportationCaller.get(path, {
      responseType: 'blob',
    }).then(response => {
      if (response) {
        const blob = new Blob([response.data], {
          type: 'application/pdf',
        });
        setPdfUrl(URL.createObjectURL(blob));
      }
    });
  }, [loadId]);

  const HandleSendEmail = e => {
    setSendEmailStatus('pending');
    e.preventDefault();
    const payload = {
      toRecipientAddresses: emailRecipients,
      subject: e.target.subject.value,
      body: e.target.body.value,
      loadId: loadId

    }
    PostBody(`/Load/SendLoadDispatchPDF`, payload).then(response => {
        if (response) {
          setSendEmailStatus('success');
          RefreshPage(null, ['Details', 'Audit', 'Summary']);
        } else {
          setSendEmailStatus('failure');
        }
      });
    };

  let sendDispatchButtonText: string;
  if (sendEmailStatus === 'success') {
    sendDispatchButtonText = 'Email Sent'
  } else if (sendEmailStatus === 'pending') {
    sendDispatchButtonText = 'Sending...'
  } else {
    sendDispatchButtonText = 'Send Email'
  }

  return (
    <>
      <h2>Load Dispatch</h2>
      <form onSubmit={e => HandleSendEmail(e)}>
        <div
          className={`${classes.attribute} ${classesMultipleEmailInput.container}`}
        >
          <label htmlFor="toRecipientAddresses">To: <span className={classes.required}>*</span></label>
          <MultipleEmailInput
            submitButtonId=''
            emails={emailRecipients}
            setEmails={setEmailRecipients}
            id="toRecipientAddresses"
            name="toRecipientAddresses"
            required={emailRecipients?.length === 0}></MultipleEmailInput>
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label htmlFor="subject">
            Subject <span className={classes.required}>*</span>
          </label>
          <Input
            required
            type="text"
            defaultValue={`Dispatch BBI Load #${loadId}`}
            id="subject"
            name="subject"/>
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label htmlFor='body'>Body: </label>
          <TextArea
          required
          defaultValue="See attached."
          id="body"
          name="body"/>
        </div>
        <Button
          type='submit'
          variant='good'
        disabled={sendEmailStatus === 'pending' || sendEmailStatus === 'success'}>{sendDispatchButtonText}
        </Button>
      </form>

      <div className={classes.pdfContainer}>
        <iframe title="PDF" id="iframe" src={pdfUrl} />
      </div>
    </>
  );
}

export default LoadDispatch;
